import React, { useEffect, useState } from 'react'
import { Badge, Button, Container, Dropdown } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GetAllCitiesDetail, CheckAreaNameAvailable, CreateArea, UpdateArea, DeleteArea, AreaBulkUpdateArea, GetAllAreasDetailPagination, GetAllAreasDetail, GetAllStatesDetail, GetAllCitiesByState } from '../../utils/proxy/Countryproxy'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { Parser } from "@json2csv/plainjs";
import Helper from '../../utils/helperFunctions';
import Select from "react-select";
import LoadingComponent from '../Includes/LoadingComponent';
import { HiDotsVertical } from "react-icons/hi";
import { SiDatabricks } from 'react-icons/si';
import { IoHomeOutline } from 'react-icons/io5';
import { Op_per } from '../../utils/Permissions';

function Areamaster() {

  const [areas, setAreas] = useState([]);
  const [citys, setCitys] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const [city_id, setCityId] = useState();
  const [areaname, setAreaName] = useState('');
  const [isareaname, setIsAreaName] = useState();
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [states, setStates] = useState([]);
  const [stateid, setStateId] = useState();
  const [state, setState] = useState();

  const [status, setStatus] = useState("");
  const [pending, setPending] = useState(true);

  const [perPage, setPerage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [Page, setPage] = useState();
  const [currPage, setCurrPage] = useState();

  const [currentId, setCurrentId] = useState();
  const [editShow, setEditShow] = useState(false);

  const filteredData = areas.filter((row) =>
    row.areaname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getallareas = async (page) => {
    let areasdata = await GetAllAreasDetailPagination(page, perPage, searchQuery);
    if (areasdata) {
      setAreas(areasdata[0].areas);
      setTotalRows(areasdata[0].totalrows);
      setPage(areasdata[0].currentpage);
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert('Error', 'Issue While Fetching Territories');
    }
  }

  useEffect(() => {
    // Fetch data from an API when the component mounts
    getallareas(currPage == undefined ? 0 : currentPage);
    SetReload(false);
  }, [reload, searchQuery]);

  const handlePaginationChange = page => {

    getallareas(page - 1);

  }

  const handlePerRowChange = async (newPerPage, page) => {
    setPerage(newPerPage);
    const areasdata = await GetAllAreasDetailPagination(page - 1, newPerPage, searchQuery);
    if (areasdata) {
      setAreas(areasdata[0].areas);
      setTotalRows(areasdata[0].totalrows)
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    };
  }

  useEffect(()=>{
    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();
  },[])

  useEffect(() => {
    if (state > 0) {
    // Fetch data from an API when the component mounts
    const getallcitys = async (state) => {
      let citysdata = await GetAllCitiesByState(state);
      if (citysdata) {
        setCitys(citysdata);
      } else {
        alert('Error', 'Issue While Fetching Cities');
      }
    }
    getallcitys(state);
  }
  }, [show,state]);

  useEffect(() => {
    const getallcountries = async (areaname) => {
      if (areaname.length > 0) {
        let statedata = await CheckAreaNameAvailable(areaname);
        if (statedata) {
          setIsAreaName(true);
        } else {
          setIsAreaName(false);
        }
      }

    }
    getallcountries(areaname);


  }, [areaname]);


  // Function to handle page change
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Pagination configuration
  const itemsPerPage = 10; // Number of items per page
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

  // Calculate the current page's items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = filteredData.slice(startIndex, endIndex);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditShow = (row) => {
    console.log(row);
    setState(row.stateid)
    setCityId(row.city_id);
    setStatus(row.areastatus);
    setAreaName(row.areaname);
    setCurrentId(row.id);
    setEditShow(true)
  };

  const handleEditClose = () => {setEditShow(false);setState();setCityId();setAreaName()}


  const handleCountryCodeChange = (event) => {
    setCityId(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryNameChange = (event) => {
    setAreaName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  }

  let createState = async (city_id, areaname) => {

    let statedata = await CreateArea(city_id, areaname);;
    if (statedata) {
      SetReload(true);
      setShow(false);


      Swal.fire({
        title: 'Success!',
        text: 'Teritories Added Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Adding Teritories');
    }
  }

  let handleSave = (event) => {
    if (isareaname == false && areaname.length > 0) {
      createState(city_id, areaname);
    }
  }

  let updateAreaEdit = async (currentId, city_id, areaname, status) => {
    let statedata = await UpdateArea(currentId, city_id, areaname, status);;
    if (statedata) {
      SetReload(true);
      setEditShow(false);


      Swal.fire({
        title: 'Success!',
        text: 'Teritories Updated Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Updating Teritories');
    }
  }

  let handleedit = (event) => {
    if (currentId && city_id && areaname && status) {
      updateAreaEdit(currentId, city_id, areaname, status)
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Fill All Details',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }


  }

  let deleteArea = async (id) => {

    let statedata = await DeleteArea(id);
    if (statedata) {
      SetReload(true);

      Swal.fire({
        title: 'Success!',
        text: 'Teritories Deleted Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Deleting Teritories');
    }
  }

  let handledelete = (id) => {
    deleteArea(id);
  }

  // export excel
  const handleExcelExport = () => {
    Swal.fire({
      title: "Exporting!",
      text: "Exporting Excel !!",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading()
        let d = await GetAllAreasDetail();
        if (d) {
          Swal.hideLoading();
          Swal.close();
          downloadCSV(d);
        }
      }
    });
  }

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      width:"4%",
      fontSize: 8,
      sortable: true,
    },
    {
      name: "Country Code",
      selector: (row) =>
       <div style={{ whiteSpace: 'normal',backgroundColor:  row.countrycode === "" ||  row.countrycode === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.countrycode === "" ||  row.countrycode === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.countrycode === "" ||  row.countrycode === null ? "#fff": "#000", margin:"3px", }}>{ row.countrycode ?  row.countrycode : "N/A"}</div>
     ,
     width:"6%",
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor:  row.countryname === "" ||  row.countryname === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.countryname === "" ||  row.countryname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.countryname === "" ||  row.countryname === null ? "#fff": "#000", margin:"3px", }}>{ row.countryname ?  row.countryname : "N/A"}</div>
     ,
     width:"8%",
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor:   row.statename === "" ||   row.statename === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.statename === "" ||   row.statename === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.statename === "" ||   row.statename === null ? "#fff": "#000", margin:"3px", }}>{  row.statename ?   row.statename : "N/A"}</div>,
      sortable: true,
      width:"13%",
      
    },
    {
      name: "City",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor:   row.cityname === "" ||   row.cityname === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.cityname === "" ||   row.cityname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.cityname === "" ||   row.cityname === null ? "#fff": "#000", margin:"3px", }}>{  row.cityname ?   row.cityname : "N/A"}</div>,
      width:"12%",
      sortable: true,
    },
    {
      name: "Territories",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:   row.areaname === "" ||   row.areaname === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.areaname === "" ||   row.areaname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.areaname === "" ||   row.areaname === null ? "#fff": "#000", margin:"3px", }}>{  row.areaname ?   row.areaname : "N/A"}</div>,
      sortable: true,
      width:"12%",
    },
    {
      name: "Status",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor:   row.areastatus === "" ||   row.areastatus === null ? "red":"#D9E1FF", borderRadius:"10px", border:   row.areastatus === "" ||   row.areastatus === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:  row.areastatus === "" ||   row.areastatus === null ? "#fff": "#000", margin:"3px", }}>{  row.areastatus === 'ACTIVE' ?  'Approved' : (row.areastatus ?? "N/A")}</div>,
      width:"12%",
      sortable: true,
    },
  ];

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  if (Op_per.get().MASTERS_TERRITORIES.ALLOW_DELETE || Op_per.get().MASTERS_TERRITORIES.ALLOW_EDIT) {
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiFillDelete
            onClick={() => handledelete(row.id)}
            style={{ color: "red", fontSize: 20,display:Op_per.get().MASTERS_TERRITORIES.ALLOW_DELETE ? '' : 'none' }}
          />
          &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            onClick={() => handleEditShow(row)}
            style={{ color: "black", fontSize: 20,display:Op_per.get().MASTERS_TERRITORIES.ALLOW_EDIT ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  async function downloadCSV(areas) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "countrycode",
          "countryname",
          "statename",
          "cityname",
          "areaname",
          "areastatus",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(areas);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(areas)} />,
    [areas]
  );

  const handleBulkUpdateSourceData = () => {
    let newArr = [];
    selectedRows.map((obj) => {
      let option = {

        // ...obj,
        city_id: obj.city_id,
        area: obj.area,
        areas: Helper.SetTableDataInDropdown(obj.areas, "id", "areaname"),
        users: Helper.SetTableDataInDropdown(obj.users, "id", "username"),
        address: obj.address,
        country: { id: obj.countryid, name: obj.countryname },
        state: { id: obj.stateid, statename: obj.statename },
        city: { id: obj.cityid, cityname: obj.cityname },
        pincode: obj.pincode,
        phone: obj.phone,
        email: obj.email,
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  let handleBulkAreaUpdate = async () => {
    let newData = bulkSourceData.map((data) => {
      return {
        city_id: data.city_id,
        areas: Helper.GetIdsFromObj(data.areas),
        clinicname: data.clinicname,
        users: Helper.GetIdsFromObj(data.users),
        address: data.address,
        countryid: data.country.id,
        stateid: data.state.id,
        cityid: data.city.id,
        pincode: data.pincode,
        phone: data.phone,
        email: data.email,
      };
    });

    let response = await AreaBulkUpdateArea(newData);
    if (response) {
      SetReload(true);
      setShowBulkAreaEdit(false);

      Swal.fire({
        title: "Success!",
        text: "Teritories Bulk Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Data");
    }
  };

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/area-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/area-master"><span class="icon icon-beaker"> </span>Territories </a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
              className="glass"
              style={{height: 46,
            width:250,
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 && Op_per.get().MASTERS_TERRITORIES.ALLOW_EDIT ? (
              <div class="dropdown" style={{ marginRight: 10 }}>
                <button
                  class="btn btn-secondary"
                  type="button"
                  variant="warning"
                  title="Bulk Menu"
                  id="dropdown-basic"
                  style={{ backgroundColor: " #FFCA2C" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <SiDatabricks color="#000" />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleBulkUpdateSourceData}>
                    <a class="dropdown-item"> Update</a>
                  </li>
                 
                 
                </ul>
              </div>
            ) : (
              ""
            )}
            {Op_per.get().MASTERS_TERRITORIES.ALLOW_ADD || Op_per.get().MASTERS_TERRITORIES.ALLOW_EXPORT_XLSX ? (

              <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_TERRITORIES.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  
                  <li onClick={handleExcelExport} style={{display:Op_per.get().MASTERS_TERRITORIES.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Export XLSX</a>
                  </li>
                </ul>
              </div>
             
            ) : ("")}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            data={areas}
            // progressPending={pending}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePaginationChange}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            dense
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleStateChange} required
               style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select className="selectdoctor" value={city_id} onChange={handleCountryCodeChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.city}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Area Name <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: isareaname ? "red" : "",
                  color: isareaname ? "#fff" : "",
                  height: 46,
           
            borderColor: '#28B3D2',
            borderWidth: 2,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleCountryNameChange}
                placeholder="Enter City Name"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isareaname ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={editShow} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Territories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleStateChange} value={state} required
               style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleCountryCodeChange} value={city_id} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.city}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Territories<span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 46,
           
           borderColor: '#28B3D2',
           borderWidth: 2,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderTopLeftRadius:20,
           borderBottomRightRadius:20,}}
                type="text"
                onChange={handleCountryNameChange}
                placeholder="Enter Territory Name"
                value={areaname}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Status <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={handleStatusChange} value={status} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Status---</option>
                <option value="ACTIVE">Approve</option>
                <option value="PENDING_FOR_ADDITION">
                  Pending For Addition
                </option>
                <option value="PENDING_FOR_DELETION">
                  Pending For Deletion
                </option>
                <option value="INACTIVE">Inactive</option>
                <option value="REJECTED">Rejected</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleedit}

          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Update Territories</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead>
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : cellIndex === 1 ? "sticky-col2" : ""}
                            >
                              {cell === "status" || cell == "city" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "city" ? citys : [
                                        { value: "ACTIVE", label: "ACTIVE" },
                                        { value: "PENDING_FOR_ADDITION", label: "PENDING_FOR_ADDITION" },
                                        { value: "PENDING_FOR_DELETION", label: "PENDING_FOR_DELETION" },
                                        { value: "INACTIVE", label: "INACTIVE" },
                                        { value: "REJECTED", label: "REJECTED" },
                                      ]
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    getOptionLabel={(option) => cell === "city" ? option.cityname : option.label}
                                    getOptionValue={(option) => cell == "city" ? option.id : option.value}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update Territories
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default Areamaster