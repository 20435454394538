import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const GetAllCompanyContacts = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/compaycontact',null);
}

export const GetAllCompanyContactsMini = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/compaycontact/mini',null);
}

export const GetAllCompanyContactsByAccountId = async (accountid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/compaycontact/accountid/'+accountid,null);
}

export const CreateCompanyContact = async (contact) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/compaycontact',contact);
}

export const CreateMultipleCompanyContact = async (contact) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/compaycontact/multiple',contact);
}

export const UpdateCompanyContact = async (id,contact) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/compaycontact/'+id,contact);
}

export const DeleteCompanyContact = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/compaycontact/'+id,null);
}