import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../../src/utils/proxy/Authproxy";
import { DeleteTourPlan, GetAllTourPlan, UpdateBulkStatus, UpdateStatusTourPlan } from "../../../src/utils/proxy/Tourplanproxy";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { IoHomeOutline } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Op_per } from "../../utils/Permissions";
import LoadingComponent from "../Includes/LoadingComponent";

function Tourprogram() {

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push({ value: i.toString(), label: i.toString() });
  }

  const [userstockit, setUserStockit] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "all");
  const [areatype, setAreaType] = useState("all");
  const [status, setStatus] = useState("PENDING_FOR_ADDITION,ACTIVE");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
  const [users, setUsers] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [pending, setPending] = useState(false);

  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
  const [selectedYear, setSelectedYear] = useState(years[0]); // Set the default year to the current year

  const [currentid, setCurrentId] = useState();
  const [upstatus, setUpStatus] = useState("ACTIVE");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleStatusChange = (event) => {
    setUpStatus(event.target.value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallteritories = async (userid, selectedMonth, selectedYear) => {
      setPending(true);
      let userteritories = await GetAllTourPlan(
        userid,
        selectedMonth,
        selectedYear
      );

      if (userteritories) {
        setPlans(userteritories);
        setPending(false);
      } else {
        alert("Error", "Issue While Fetching Tour Plan");
      }
    };
    if (userid && selectedMonth.value && selectedYear.value) {
      getallteritories(userid, selectedMonth.value, selectedYear.value);
    }
    setReload(false);
  }, [reload, userid, showModal, selectedMonth, selectedYear]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {

      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }

    };
    getallusers();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const statusoptions = [
    { value: "ACTIVE", label: "APPROVED" },
    { value: "PENDING_FOR_ADDITION", label: "PENDING_FOR_ADDITION" },
    { value: "PENDING_FOR_DELETION", label: "PENDING_FOR_DELETION" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  const columns = [
    {
      name: "Tour Date",
      selector: (row) => row.tourdate.split("T")[0],
      sortable: true,
      width: "10%",
    },
    {
      name: "Activity Type",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.activitytype === "" || row.activitytype === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.activitytype === "" || row.activitytype === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.activitytype === "" || row.activitytype === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.activitytype ? row.activitytype : "N/A"}</div>,
      width: "15%",
    },

    {
      name: "Status",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.status ? row.status === 'ACTIVE' ? 'Approved' : row.status : "N/A"}</div>,
      width: "20%",

    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>

    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20 }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => handleDelete(row.id)}
    //         style={{ color: "red", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().TOUR_PROGRAM.ALLOW_DELETE || Op_per.get().TOUR_PROGRAM.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>

          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20, display:Op_per.get().TOUR_PROGRAM.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "red", fontSize: 20, display:Op_per.get().TOUR_PROGRAM.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    setCurrentId(row.id);
    setShow(true)
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  let handleBulkAreaUpdate = async () => {

    let newData = bulkSourceData.map((data) => {

      return {
        id: data.id,
        status: data.status.value
      };
    });



    let response = await UpdateBulkStatus(newData);
    if (response) {
      setReload(true);
      setPending(true);
      setShowBulkAreaEdit(false);
      handleClearRows();
      Swal.fire({
        title: "Success!",
        text: "Status Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Doctors");
    }
  };

  const handleBulkUpdateSourceData = () => {
    let newArr = [];


    selectedRows.map((obj) => {
      let option = {
        id: obj.id,
        tourdate: obj.tourdate.split("T")[0],
        activitytype: obj.activitytype,
        status: obj.status
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  let deleteTourProgram = async (row) => {


    let degreedata = await DeleteTourPlan(row);
    if (degreedata) {
      setReload(true);
      setShow(false)


      Swal.fire({
        title: 'Success!',
        text: 'Tour Program Deleted Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Fetching Tour Program');
    }
  }

  const handleDelete = (row) => {
    deleteTourProgram(row);
  };

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
    setUserId(selected.value);
  };

  const handleSelectChangeMOnth = (selected) => {
    setSelectedMonth(selected);
  };

  const handleSelectChangeYear = (selected) => {

    setSelectedYear(selected);
  };

  const ExpandedComponent = ({ data }) => (
    <div
      className="container p-3"
      style={{
        backgroundColor: data.disabled ? "red" : "#D9E1FF",
        color: "#fff",
        padding: 10,
        fontSize: 12,
        margin: 20,
        borderRadius: 20,
        border: "2px solid #28B3D2",
        width: "95%"
      }}
    >
      <Table>
        <tr>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10 }} >Activity  </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10 }}> <span style={{
            backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold"
          }}>{data.activitytype}</span> </td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10 }}>Territories </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10 }}>{data.teritories != null ? (
            data.teritories.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.area}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>
        </tr>
        <tr>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Doctors  </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.doctors != null ? (
            data.doctors.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.doctorname}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Chemists </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.chemists != null ? (
            data.chemists.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.chemistname}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>
        </tr>
        <tr>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Stockist  </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.stockists != null ? (
            data.stockists.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.chemistname}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Clinics </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.clinics != null ? (
            data.clinics.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.clinicname}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>
        </tr>
        <tr>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Hospitals  </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.hospitals != null ? (
            data.hospitals.map((are) => (
              <span
                style={{
                  backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
                }}
              >
                {are.hospitalname}
              </span>
            ))
          ) : (
            <span
              style={{
                backgroundColor: "#2457C5", borderRadius: "10px", border: "2px solid #28B3D2", padding: "5px", color: "#fff", fontWeight: "bold", margin: "3px"
              }}
            >
              N/A
            </span>
          )}</td>

        </tr>
      </Table>
      {/* <div className="row">
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Activity</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.activitytype}</td>
            </tr>
            <tr>
              <td>
                <b>Territories</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.teritories != null
                  ? data.teritories.map((are) => <span>{are.area} , </span>)
                  : ""}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Doctors</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.doctors != null
                  ? data.doctors.map((are) => <span>{are.doctorname} , </span>)
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                <b>Chemists</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.chemists != null
                  ? data.chemists.map((are) => (
                      <span>{are.chemistname} , </span>
                    ))
                  : ""}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Stockist</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.stockists != null
                  ? data.stockists.map((are) => (
                      <span>{are.stockistname} , </span>
                    ))
                  : ""}
              </td>
            </tr>
            <tr>
              <td>
                <b>Clinic</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.clinics != null
                  ? data.clinics.map((are) => <span>{are.clinicname} , </span>)
                  : ""}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Hospitals</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.hospitals != null
                  ? data.hospitals.map((are) => (
                      <span>{are.hospitalname} , </span>
                    ))
                  : ""}
              </td>
            </tr>
          </table>
        </div>
      </div> */}
    </div>
  );

  let updateTourProgram = async (currentid, upstatus) => {

    let degreedata = await UpdateStatusTourPlan(currentid, upstatus);
    if (degreedata) {
      setReload(true);
      setShow(false)


      Swal.fire({
        title: 'Success!',
        text: 'Tour Program Update Successfully !!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } else {
      alert('Error', 'Issue While Fetching Tour Program');
    }
  }

  const handleSave = () => {
    updateTourProgram(currentid, upstatus);
  }

  async function downloadCSV(plans) {
    let csv;
    try {
      const opts = {
        fields: [
          { label: "Tour Date", value: "tourdate" },
          { label: "Activity Type", value: "activitytype" },
          { label: "Username", value: "username" },
          { label: "First Name", value: "firstname" },
          { label: "Last Name", value: "lastname" },
          { label: "Territory", value: "teritory_area" }, // Single territory per row
          { label: "Hospitals", value: "hospitalname" },
          { label: "Doctors", value: "doctors" },
          { label: "Chemists", value: "chemists" },
          { label: "Stockists", value: "stockists" },
          { label: "Clinics", value: "clinics" },
        ],
        transforms: [
          // Unwind territories to create one row per territory
          unwind({ paths: ["teritories"] }),
          // Unwind hospitals to associate each hospital with the row and its territory
          (row) => {
            const territoryId = row.teritories?.id;
            const territoryArea = row.teritories?.area || "N/A";
        
            row.teritory_area = territoryArea;
        
            // Attach hospitals for each territory
            row.hospitals = row.hospitals
              ?.filter((hospital) => hospital.areaid === territoryId)
              .map((hospital) => ({
                id: hospital.id,
                name: hospital.hospitalname,
              })) || [];
        
            return row;
          },
          unwind({ paths: ["hospitals"] }), // Unwind hospitals to get one hospital per row
          // Add the hospital-related filtering for doctors
          (row) => {
            const territoryId = row.teritories?.id;
            const hospitalId = row.hospitals?.id;
            row.hospitalname = row.hospitals?.name;
        
            row.doctors = row.doctors
              ?.filter(
                (doctor) =>
                  doctor.areaid === territoryId && doctor.hospitalid === hospitalId
              )
              .map((doctor) => doctor.doctorname)
              .join(",") || "";

              row.chemists = row.chemists
              ?.filter(chemist => chemist.areaid === territoryId)
              .map(chemist => chemist.chemistname)
              .join(",") || "";

          row.stockists = row.stockists
              ?.filter(stockist => stockist.areaid === territoryId)
              .map(stockist => stockist.chemistname)
              .join(",") || "";

          row.clinics = row.clinics
              ?.filter(clinic => clinic.areaid === territoryId)
              .map(clinic => clinic.clinicname)
              .join(",") || "";
        
            return row;
          },
          // Unwind other fields if needed
          unwind({ paths: ["doctors", "chemists", "stockists", "clinics"], blankOut: false }),
          // Format the tour date
          (row) => {
            if (row.tourdate) {
              row.tourdate = row.tourdate.split("T")[0]; // Split and get the first part
            }
            return row;
          },
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(plans);
    } catch (err) {
      console.error(err);
    }
  
    const link = document.createElement("a");
  
    if (csv == null) return;
  
    const filename = `${selectedUser.label}_${selectedMonth.label}/${selectedYear.value}_tourplan.csv`;
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }  

  const Export = ({ onExport }) => (

    <div>
      <Button style={{ backgroundColor: "#28B3D2", display:Op_per.get().TOUR_PROGRAM.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(selectedUser && selectedMonth && selectedYear)}>
        <RiFileExcel2Fill />
      </Button>
    </div>



    //  <Button id="dropdown-basic"  style={{backgroundColor:"#28B3D2", borderTopLeftRadius:20, borderBottomRightRadius:20,color:"#fff", border:"none"}} onClick={(e) => onExport(e.target.value)}><RiFileExcel2Fill /> </Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(plans)} />,
    [plans]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: '#3887BE'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/tour-program"><span class="icon icon-beaker"> </span>Tour Program</a></li>
            {/* <li><a href="/user-teritory"><span class="icon icon-beaker"> </span> User Teritory Master</a></li> */}
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <div style={{ width: "320px",display:Op_per.get().TOUR_PROGRAM.USER_DROPDOWN ? '' : 'none'}}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Users </Form.Label>
              <div className="w-75">
                <Select
                  className="selectdoctor"
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
          <div style={{ width: "350px" }}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Select Month </Form.Label>
              <div className="w-50">
                <Select
                  className="selectdoctor"
                  options={months}
                  value={selectedMonth}
                  onChange={handleSelectChangeMOnth}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
          <div style={{ width: "450px" }}>
            <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Select Year </Form.Label>
              <div className="w-50">
                <Select
                  className="selectdoctor"
                  options={years}
                  value={selectedYear}
                  onChange={handleSelectChangeYear}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 && Op_per.get().TOUR_PROGRAM.ALLOW_EDIT ? (
              <Dropdown style={{ marginRight: 10 }}>
                <Dropdown.Toggle variant="warning" id="dropdown-basic">
                  Bulk
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleBulkUpdateSourceData()}>
                    Update
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => handleBulkDataDelete()}>
                    Delete
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={plans}
            selectableRows
            onSelectedRowsChange={handleChange}
            clearSelectedRows={toggledClearRows}
            actions={actionsMemo}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Status <span style={{color:"red"}}>*</span></Form.Label>
          <Form.Select
           className="selectdoctor"
            onChange={handleStatusChange}
            aria-label="Default select example"
            style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
          >
            <option value="ACTIVE">APPROVE</option>
            <option value="PENDING_FOR_ADDITION">PENDING_FOR_ADDITION</option>
            <option value="PENDING_FOR_DELETION">PENDING_FOR_DELETION </option>
            <option value="REJECTED">REJECTED</option>
            
          </Form.Select>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Edit Doctor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : cellIndex === 1 ? "sticky-col2" : ""}
                            >
                              {cell === "status" ? (
                                <div style={{ minWidth: "200px" }} >
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={statusoptions}
                                    value={bulkSourceData[rowIndex][cell]}
                                    disabled={cell == "status" ? true : false}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id" || cell === "tourdate" || cell === "activitytype"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update status
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default Tourprogram;
