import React, { useEffect, useRef, useState } from 'react';
import { ChangeTaskStatus, CreateTask, DeleteTaskById, GetAllTask, GetAllTaskByUser, GetAllTaskByUserCopy, GetAllTaskCategory, UpdateTaskById } from '../../utils/proxy/Taskproxy';
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import { Badge, Button, Card, Carousel, Col, Dropdown, Form, Modal, OverlayTrigger, Pagination, Row, Table, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import Select from "react-select";
import { GetAllProjects, GetAllProjectsByUserid } from '../../utils/proxy/ProjectProxy';
import { FaCheck, FaPlus, FaPrint } from 'react-icons/fa6';
import Helper from '../../utils/helperFunctions';
import { IoHomeOutline } from 'react-icons/io5';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { GrInProgress } from 'react-icons/gr';
import DataTable from 'react-data-table-component';
import { Op_per } from '../../utils/Permissions';
import { taskColor } from '../../utils/constant';
import DatePicker from 'react-date-picker';
import { IoIosClose } from "react-icons/io";
import LoadingComponent from '../Includes/LoadingComponent';
import { useLocation } from 'react-router-dom';
import { RiFileExcel2Fill, RiFileExcel2Line } from 'react-icons/ri';
import { Parser } from '@json2csv/plainjs';

function Taskpage() {
  const { state } = useLocation();
  const [data, setdata] = useState([])
  const [users, setusers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : '');
  const [reload, setReload] = useState(false);
  const [changestatus, setchangestatus] = useState('');
  const [showaddtask, setshowaddtask] = useState(false);
  const [showedit, setshowedit] = useState(false);
  const [showtaskmodal, setshowtaskmodal] = useState(false);
  const [currentmodaldata, setCurrentModaldata] = useState({ start: '', end: '' });
  const [allProject, setAllProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [localStartDate, setLocalStartDate] = useState('');
  const [localEndDate, setLocalEndDate] = useState('');
  const [utcStartDate, setUtcStartDate] = useState('');
  const [utcEndDate, setUtcEndDate] = useState('');
  const [switchview,setSwitchView] = useState(false);
  const [viewType,setViewType] = useState('Grid');
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(8);
  const [totalPages, setTotalPages] = useState();
  const [totalRows,setTotalRows] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [date, setDate] = useState((state && state.dateProp) ? new Date(state.dateProp).toISOString().split('T')[0] : '');
  const [loading, setLoading] = useState(true);

  const printRef = useRef();
  

  const [task, setTask] = useState({
    "id": null,
    "userid": selectedUserId,
    "task": null,
    "projectid": selectedProjectId,
    "categoryid": selectedCategoryId,
    "start": null,
    "end": null,
    "description": null,
    "createdby": localStorage.getItem('uuid'),
    "priority": 'medium',
    "status": "new"
  });

  function switchv(event) {

    setSwitchView(event.target.checked);
  setViewType(event.target.checked ? 'List' : 'Grid');
  }

  const filteredData = changestatus === "" ? data.tasks :
    data.tasks.filter((row) => {

      if (row.status.toLowerCase().includes(changestatus)) {
        return true;
      }

      return false;
    })

  useEffect(() => {
    async function name() {
      setLoading(false);
      let managerid = localStorage.getItem('role') === "Manager" ? localStorage.getItem('uuid') : ''
      let data = await GetAllTaskByUserCopy(selectedUserId,page,size,changestatus,managerid,date);
      if (data) {
        setdata(data);
        setTotalPages(data.totalpages)
        setTotalRows(data.totalrows)
        setCurrentPage(data.currentpage)
        setLoading(true);
      } else {
        alert("Unable To Fetch ");
      }
      setLoading(true);
    }

    name();
    setReload(false);
  }, [selectedUserId, reload,page,size,changestatus,date]);

  useEffect(() => {
    async function getProjects() {
      let data = await GetAllProjectsByUserid(selectedUserId);
      if (data) {
        setAllProject(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    async function getCategories() {
      let data = await GetAllTaskCategory();
      if (data) {
        setAllCategories(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    getProjects();
    getCategories();
  }, [selectedUserId])

  function dateToIsoStringWithoutChange(istDate) {
    if (!(istDate instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());

    const isoString = istDate.toISOString();

    return isoString;
  }


  function modalpopup(params) {
    setCurrentModaldata(params)

    setUtcStartDate(dateToIsoStringWithoutChange(new Date(params.start)))
    setUtcEndDate(dateToIsoStringWithoutChange(new Date(params.end)))
    setSelectedProject({ value: params.projectid, label: params.project });
    setSelectedProjectId(params.projectid);
    setSelectedCategory({ value: params.categoryid, label: params.categoryname })
    setSelectedCategoryId(params.categoryid)

  }


  useEffect(() => {

    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };
    getAllUsersData();
  }, []);

  const handlesubmit = async () => {
    if (task.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start == null || task.end == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start > task.end) {
      Swal.fire({
        title: "Warning!",
        text: "Start Date is Greater Than End Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      let d = await CreateTask(task);
      if (d.status === "success") {
        setshowaddtask(false);
        setReload(true)
        setTask({
          ...task,
          userid: selectedUserId,
          task: null,
          start: null,
          end: null,
          description: null,
          priority: null
        })
        setSelectedProject(null)
        setSelectedProjectId(null)
        setSelectedCategory(null)
        setSelectedCategoryId(null)
        Swal.fire({
          title: "Success!",
          text: "Task Added Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("Error", "Issue While Adding Task");
      }
    }
  }

  const handleeditsubmit = async () => {
    if (currentmodaldata.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.start == null || currentmodaldata.end == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.start > currentmodaldata.end) {
      Swal.fire({
        title: "Warning!",
        text: "Start Date is Greater Than End Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      currentmodaldata.updatedat = '';
      let d = await UpdateTaskById(currentmodaldata.id, currentmodaldata);
      if (d.status === "success") {
        setshowedit(false);
        setReload(true);
        Swal.fire({
          title: "Success!",
          text: "Task Updated Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("something went wrong");
      }
    }
  }

  const handletaskdelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let d = await DeleteTaskById(id);
        if (d.status === "success") {
          setReload(true)
          Swal.fire({
            title: "Deleted!",
            text: "Task Deleted Successfully !!",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      }
   
    });
    
  }

  const handlestatuschange = async (id, status) => {
    let d = await ChangeTaskStatus(id, status);
    if (d.status === 'success') {
      setReload(true)
      Swal.fire({
        title: "Success!",
        text: "Task Status Changed !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }

  async function downloadCSV(plans) {
    let csv;
    try {
      const opts = {
        fields: [
          { label: "User Name", value: "username" },
          { label: "Task", value: "task" },
          { label: "Description", value: "description" },
          { label: "Project", value: "project" },
          { label: "Category Name", value: "categoryname" },
          { label: "Start", value: "start" },
          { label: "End", value: "end" },
          { label: "Createdby", value: "createdbyusername" },
          { label: "Priority", value: "priority" },
          { label: "Status", value: "status" },
        ],
        transforms: [
          // unwind({
          //   paths: [
          //     "teritories",
          //     "chemists",
          //     "hospitals",
          //     "clincs",
          //     "doctors",
          //     "stockists",
          //   ],
          // }),
          // flatten({ object: false, array: true, separator: "_" }),
          // Split the tourdate column and get the first part
          (row) => {
            if (row.start) {
              row.start = Helper.ConvertUtcToLocalTime(row.start); // Split and get the first part
            }
            return row;
          },
          (row) => {
            if (row.end) {
              row.end = Helper.ConvertUtcToLocalTime(row.end); // Split and get the first part
            }
            return row;
          },
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(plans);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = `task_export_${new Date().toISOString().split('T')[0]}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const onModalOpen = () =>{
    let st = new Date();
    let ed = new Date();
    ed.setHours(ed.getHours() + 1);
    setTask({...task, start:st.toISOString(),end:ed.toISOString(),priority:'medium' })
    setshowaddtask(true);
    setLocalStartDate(dateToIsoStringWithoutChange(st).substr(0,16));
    setLocalEndDate(dateToIsoStringWithoutChange(ed).substr(0,16))
  }

  const options = [];
  const projects = [];
  const category = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  allProject.map((project) => {
    projects.push({
      value: project.id,
      label: project.name
    })
  })

  allCategories.map((cat) => {
    category.push({
      value: cat.id,
      label: cat.name
    })
  })

  const handleSelectChange = (selected) => {
    if (selected == null) {
      setSelectedUsers(null);
      setSelectedUserId('')
      setTask({...task,userid:''})
      setCurrentModaldata({...currentmodaldata,userid:''})
      return;
    }
    setSelectedUsers(selected);
    setTask({ ...task, userid: selected.value });
    setCurrentModaldata({ ...currentmodaldata, userid: selected.value });
    setSelectedUserId(selected.value);
    setchangestatus('');
    setSelectedProject(null);
    setPage(0);
    setSize(8);
  };

  const handleProjectChange = (selected) => {
    if (selected === null) {
      setTask({ ...task, projectid: null });
      setCurrentModaldata({ ...currentmodaldata, projectid: null })
      setSelectedProject();
      setSelectedProjectId();
    } else {
      setTask({ ...task, projectid: selected.value });
      setCurrentModaldata({ ...currentmodaldata, projectid: selected.value })
      setSelectedProject(selected);
      setSelectedProjectId(selected.value);
    }

  };

  const handleCategoryChange = (selected) => {
    if (selected === null) {
      setTask({ ...task, categoryid: null })
    setCurrentModaldata({ ...currentmodaldata, categoryid: null })
    setSelectedCategory();
    setSelectedCategoryId();
    } else {
      setTask({ ...task, categoryid: selected.value })
    setCurrentModaldata({ ...currentmodaldata, categoryid: selected.value })
    setSelectedCategory(selected);
    setSelectedCategoryId(selected.value);
    }
    
  }

  const handleDateFilter = (value) => {
    if (value == null) {
      setDate('');
      return;
    }
    setDate(dateToIsoStringWithoutChange(value).split('T')[0]);
  };

  const clearDate = () => {
    setDate('');
  };

  const handlePageChange = (action) =>{
    if(action === 'prev'){
      setPage(page => page - 1);
    }else{
      setPage(page => page + 1);
    }
  }

  const handleFirstLastPage = (param) =>{
    setPage(param);
  }

  const handlePageChangeTable = (page) =>{
    setPage(page-1);
  }

  const handlePerRowChange = (newPerPage, page) =>{
    setSize(newPerPage);
    setPage(page-1);
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 6; // Number of page buttons to display at once
    const halfRange = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    // Adjust if we're near the start or end
    if (currentPage <= halfRange) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfRange >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Pagination.Item key={0} onClick={() => setPage(0)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        pageNumbers.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage+1}
          onClick={() => {setPage(i-1)}}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      pageNumbers.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => setPage(totalPages-1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    // },
    {
      name: "Task",
      selector: (row) => <div style={{fontSize:12}}>{row.task}</div>,
    },
    {
      name: "User",
      selector: (row) => <div style={{fontSize:12}}>{row.username}</div>,
    },
    {
      name: "Start",
      selector: (row) => <div style={{fontSize:12}}>{Helper.ConvertUtcToLocalTime(row.start).split(" ")[0]}</div>,
      sortable:true
    },{
      name: "End",
      selector: (row) => <div style={{fontSize:12}}>{Helper.ConvertUtcToLocalTime(row.end).split(" ")[0]}</div>,
      sortable:true
    },{
      name: "Priority",
      selector: (row) => <div style={{fontSize:12}}>{row.priority.toUpperCase()}</div>,
      sortable:true
    },
    {
      name: "Status",
      selector: (row) => <div style={{fontSize:12}}>{row.status.toUpperCase()}</div>,
    },
    

    {
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          {row.status !== 'completed' && <><AiTwotoneEdit
                        onClick={() => { modalpopup(row); setshowedit(true) }}
                        style={{ color: "black", fontSize: 20, margin:5 }}
                    />
          
          <AiFillDelete 
                        onClick={() => handletaskdelete(row.id)}
                        style={{ color: "red", fontSize: 20, margin:5 }}
                    /></>}

          {row.status === 'new'  ? <>
                        {/* <Button  style={{margin:10}} size='sm' onClick={() => handlestatuschange(row.id, 'inprogress')}><GrInProgress /></Button> */}
                        <GrInProgress size={18}
                       onClick={() => handlestatuschange(row.id, 'inprogress')}
                        style={{ color: "black", fontSize: 20, margin:5 }}
                    />


                    {/* <Button style={{margin:10,backgroundColor:'green'}} size='sm' onClick={() => handlestatuschange(row.id, 'completed')}><FaCheck /></Button> */}

                    <FaCheck size={18}
                       onClick={() => handlestatuschange(row.id, 'completed')}
                        style={{ color: "black", fontSize: 20, margin:5 }}
                    />

                   </> :  null}
                   {row.status === 'inprogress' ? <>
                        {/* <Button  style={{margin:10}} size='sm' onClick={() => handlestatuschange(row.id, 'inprogress')}><GrInProgress /></Button> */}
                       

                    <FaCheck size={18}
                       onClick={() => handlestatuschange(row.id, 'completed')}
                        style={{ color: "black", fontSize: 20, margin:5 }}
                    />

                   </> :  null}
          {/* <AiTwotoneEdit
            style={{ color: "black", fontSize: 20 }}
            
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            
            style={{ color: "red", fontSize: 20 }}
          /> */}
        </div>
      ),
      width:"200px"
    },
  ];

  const Export = ({ onPrint, onExport }) => (

    <>
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Print</Tooltip>}>
    <Button style={{ backgroundColor: "#28B3D2 "}} onClick={(e) => onPrint()}>
      <FaPrint />
    </Button>
    </OverlayTrigger>
    
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Excel</Tooltip>}>
    <Button style={{ backgroundColor: "#28B3D2 "}} onClick={(e) => onExport()}>
      <RiFileExcel2Line />
    </Button>
    </OverlayTrigger>
    </>
  );

  const actionMemo = React.useMemo(
    () => <Export onPrint={() => {handlePrint()}} onExport={()=>{downloadCSV(filteredData)}} />,
    [filteredData]
  );

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const selectedRowChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  const handlePrint = () => {
    const printContent = printRef.current;

  // Open a new window
  const printWindow = window.open('', '', 'width=800,height=600');

  // Get the current document's styles
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('');
      } catch (e) {
        console.warn('Could not load styles for printing:', e);
        return '';
      }
    })
    .join('');

  // Write content and styles to the new window
  printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>${styles}</style>
      </head>
      <body>${printContent.innerHTML}</body>
    </html>
  `);

  // Close the document and print
  printWindow.document.close();
  printWindow.print();
  };

  const ExpandedComponent = ({ data }) => (
    <div style={{
      backgroundColor: data.disabled ? "red" : "#D9E1FF",
      color: "#fff",
      padding: 10,
      fontSize: 12,
      margin:20,
      borderRadius:20,
      border:"2px solid #28B3D2",
      width:"95%"
      
    }}>
      <Table
        // striped
        // hover
        
        size="sm"
        // style={{
        //   backgroundColor: data.disabled ? "red" : "#D9E1FF",
        //   color: "#fff",
        //   padding: 10,
        //   fontSize: 12,
        //   margin:20,
        //   borderRadius:20,
        //   border:"2px solid #28B3D2",
        //   width:"95%"
          
        // }}
      >
      <tr style={{padding:20}}>
        <td style={{fontWeight:"bold", width:"10%"}} >Task</td>
        <td className='pr-table-seprator'>:</td>
        <td style={{  width:"30%" }}> <span  >{data.task}</span> </td>
                        <td  style={{fontWeight:"bold", width:"10%"}}>Description</td>
                        <td className='pr-table-seprator'>:</td>
        <td style={{ width:"40%"}}>{data.description}</td>
    </tr>

    <tr>
         
                <td  style={{fontWeight:"bold", width:"10%"}}>Start</td>
               <td className='pr-table-seprator'>:</td>
        <td style={{ width:"30%" }} > <span  > {Helper.ConvertUtcToLocalTime(data.start)}</span></td>

                <td  style={{fontWeight:"bold", width:"10%"}}>End </td>
       <td className='pr-table-seprator'>:</td>
        <td style={{width:"30%" }}> {Helper.ConvertUtcToLocalTime(data.end)}</td>
    </tr>

    <tr>
        <td  style={{fontWeight:"bold", width:"10%"}}>Priority</td>

       <td className='pr-table-seprator'>:</td>
        <td style={{width:"30%" }}> <span > {data.priority} </span></td>
                <td  style={{fontWeight:"bold", width:"10%"}}>Assigned by </td>
               <td className='pr-table-seprator'>:</td>
        <td style={{width:"30%" }}>{data.createdbyusername}</td>   </tr>

    <tr>
    
        <td  style={{fontWeight:"bold", width:"10%"}}>Project Category </td>
       <td className='pr-table-seprator'>:</td>
        <td style={{width:"30%" }}> <span >{data.categoryname} </span></td>

                <td style={{fontWeight:"bold", width:"10%"}}>Project Assigned </td>
               <td className='pr-table-seprator'>:</td>
        <td style={{width:"30%" }}> <span > {data.project} </span></td>
    </tr>
   
   
   
    {/* <tr  >
        <td  style={{fontWeight:"bold", color:"#000" , padding:10}}>Degrees </td>
        <td>:</td>
        <td style={{ padding:10 }}> {data.degrees != null && data.degrees.length > 0 ? (
                  data.degrees.map((are) => (
                    <span
                      style={{
                        backgroundColor:"#2457C5", borderRadius:"10px", border: "2px solid #28B3D2",padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.degree}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                    backgroundColor:"red", borderRadius:"10px", padding:"5px",color:"#fff", fontWeight:"bold",margin:"3px", border:"2px solid red"
                       }}
                  >
                  
                    N/A
                  </span>
                )}</td>
    </tr> */}
        {/* <tbody >
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Name :</td>
           
            <td style={{ color: "#fff" }}>{data.doctorname}</td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Users :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.users != null ? (
                  data.users.map((user) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {user.username}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Areas :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.areas != null ? (
                  data.areas.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.areaname}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Code :</td>
           
            <td style={{ color: "#fff" }}>
              {data.doctorcode === "" || data.doctorcode === null
                ? "N/A"
                : data.doctorcode}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Doctor Aadhar :</td>
            
            <td style={{ color: "#fff" }}>
              {data.doctoraadhar === "" || data.doctoraadhar === null
                ? "N/A"
                : data.doctoraadhar}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>
              Specializations :
            </td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.specializations != null ? (
                  data.specializations.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.specialization}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Address :</td>
           
            <td style={{ color: "#fff" }}>
              {data.address === "" || data.address === null
                ? "N/A"
                : data.address}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Pincode :</td>
           
            <td style={{ color: "#fff" }}>
              {data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>City :</td>
            
            <td style={{ color: "#fff" }}>
              {data.city === "" || data.city === null ? "N/A" : data.city}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Date Of Birth :</td>
           
            <td style={{ color: "#fff" }}>
              {data.dateofbirth === "" || data.dateofbirth === null
                ? "N/A"
                : data.dateofbirth}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>
              Aniversary Date :
            </td>
            
            <td style={{ color: "#fff" }}>
              {data.aniversarydate === "" || data.aniversarydate === null
                ? "N/A"
                : data.aniversarydate}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Cips :</td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.cips != null ? (
                  data.cips.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.name}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
          </tr>

          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Clinics :</td>
            
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.clinics != null ? (
                  data.clinics.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.name}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Hospitals :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.hospitals != null ? (
                  data.hospitals.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.hospitalname}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Email :</td>
           
            <td style={{ color: "#fff" }}>
              {data.email === "" || data.email === null ? "N/A" : data.email}
            </td>
            <td style={{ color: "#000", fontWeight: "bold" }}>Phone :</td>
           
            <td style={{ color: "#fff" }}>
              {data.phone === "" || data.phone === null ? "N/A" : data.phone}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", fontWeight: "bold" }}>Degrees :</td>
           
            <td style={{ color: "#fff" }}>
              <ul style={{ listStyle: "none" }}>
                {data.degrees != null ? (
                  data.degrees.map((are) => (
                    <li
                      style={{
                        backgroundColor: "#000",
                        margin: 2,
                        padding: 2,
                        borderRadius: 10,
                      }}
                    >
                      {are.degree}
                    </li>
                  ))
                ) : (
                  <li
                    style={{
                      backgroundColor: "#000",
                      margin: 2,
                      padding: 2,
                      borderRadius: 10,
                    }}
                  >
                    N/A
                  </li>
                )}
              </ul>
            </td>
          </tr>
        </tbody> */}
      </Table>
    </div>
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "new",
      style: {
        backgroundColor: taskColor.newbackground,
        color: taskColor.newtext,
      },
      
    },
    {
      when: (row) => row.status === "inprogress",
      style: {
        backgroundColor: taskColor.inprogressbackground,
        color: taskColor.inprogresstext,
      },
    },
    {
      when: (row) => row.status === "completed",
      style: {
        backgroundColor: taskColor.completedbackground,
        color: taskColor.completedtext,
      },
    }
  ];

  return (
    <>
    
    <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/tasks"><span class="icon icon-beaker"> </span>Tasks</a></li>
  {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
               
            </div>

      <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: localStorage.getItem('role') === 'User' ? 'flex-end' : 'space-between', alignItems: 'center', margin: '20px' }}>
      <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap:5
            }}
          >
        <div style={{ width: '300px', display: Op_per.get().TASKS.USER_DROPDOWN ? '' : 'none' }}>
          <Form.Group
            className="mb-3 d-flex w-100"
            style={{ gap: "15px" }}
            controlId="formBasicPassword"
          >
            <Form.Label className="mt-2">Users</Form.Label>
            <div className="selectdoctor">
              <Select

                options={options}
                value={selectedUsers}
                isClearable={true}
                onChange={handleSelectChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />
            </div>
          </Form.Group>

        </div>

        <div style={{ width: "350px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Date</Form.Label>
                <div className="mt-2">
                  <DatePicker
                    clearIcon={<IoIosClose onClick={()=>clearDate()} />}
                    onChange={(date) => handleDateFilter(date)}
                    value={date}
                    format="dd-MM-yy"
                  />
                </div>
              </Form.Group>
            </div>
        </div>

        <div style={{ float: 'right' }}>
          <Button style={{ backgroundColor: "#28B3D2", display:Op_per.get().TASKS.ALLOW_ADD ? '' : 'none' }} onClick={onModalOpen}>
            <FaPlus />
          </Button>
        </div>

        {/* <div>
          <div style={{backgroundColor:"#28B3D2", borderTopLeftRadius:20, borderBottomRightRadius:20,color:"#fff"}} className='add-button' onClick={() => setshowaddtask(true)} >Add Task</div>
        </div> */}
      </div>




      <hr />
      

      <div style={{justifyContent:'space-between',display:'flex',flexDirection:'row'}}>
      <div style={{ marginBottom: 20 }}>
        <span className='badge' onClick={() => { setchangestatus('');setPage(0) }} style={{backgroundColor:"#6C757D", margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === '' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="secondary">All</span>

        <span  className='badge' onClick={() => { setchangestatus('new');setPage(0) }} style={{backgroundColor:"#ADEAFE", color:"black",margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'new' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} >NEW</span>

        <span className='badge' onClick={() => { setchangestatus('inprogress');setPage(0) }} style={{backgroundColor:"#FDE767", color:"black", margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'inprogress' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} $badge-font-size = '100px' bg="warning">IN-PROGRESS</span>

        <span className='badge' onClick={() => { setchangestatus('completed');setPage(0) }} style={{backgroundColor:"#6DEE7A",  color:"black", margin: 3, padding: 6, cursor: 'pointer', boxShadow: changestatus === 'completed' ? 'black 1px 1px 1px 1px' : 'black 0px 0px 0px 0px' }} bg="success">COMPLETED</span>
      </div>
        <div>
        <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label={`${viewType} View`}
        checked={switchview}
        onChange={switchv}
      />
        </div>
      </div>

      {!switchview ? 
      (loading ? <Row ref={printRef}>
      {(filteredData && filteredData.length > 0) ? filteredData.map((task) => (
        <Col md={3} >
          <Card className='card-1' style={{ borderRadius: 20, backgroundColor: task.status == 'new' ? '#ADEAFE' : task.status == 'inprogress' ? '#FDE767' : '#6DEE7A', height: '252px', overflow: 'hidden' }}>
            <Dropdown style={{display:task.status !== 'completed' ? '' : 'none'}}>
              <Dropdown.Toggle  style={{backgroundColor: task.status == 'new' ? '#0086ff' : task.status == 'inprogress' ? '#ffbd00' : '#00b212',border:"none"}} className='text-right position-absolute top-0 end-0 pr-3 pt-2'></Dropdown.Toggle>
              <Dropdown.Menu style={{ WebkitAppearance: 'none' }}>
                <Dropdown.Item style={{display:Op_per.get().TASKS.ALLOW_EDIT ? '' : 'none'}} onClick={() => { modalpopup(task); setshowedit(true) }}>
                  Update
                </Dropdown.Item>
                <Dropdown.Item style={{display:Op_per.get().TASKS.ALLOW_DELETE ? '' : 'none'}} onClick={() => handletaskdelete(task.id)}>
                  Delete
                </Dropdown.Item>
                {(() => {
                  if (task.status === 'new') {
                    return (
                      <>
                        <Dropdown.Item onClick={() => handlestatuschange(task.id, 'inprogress')}>Mark As Inprogress</Dropdown.Item>
                        <Dropdown.Item onClick={() => handlestatuschange(task.id, 'completed')}>Mark As Complete</Dropdown.Item>
                      </>
                    )
                  } else if (task.status === 'inprogress') {
                    return (
                      <Dropdown.Item onClick={() => handlestatuschange(task.id, 'completed')}>Mark As Complete</Dropdown.Item>
                    )
                  }

                  return null;
                })()}
              </Dropdown.Menu>
            </Dropdown>
            <Card.Body>
              <Card.Title className='card-title' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={() => { modalpopup(task); setshowtaskmodal(true) }}>{task.task}</Card.Title>
              <Card.Text>
                <div style={{ height: "47px", maxHeight: "50px", overflow: 'hidden',display:'-webkit-box',WebkitLineClamp:2,WebkitBoxOrient:'vertical' }} onClick={() => { modalpopup(task); setshowtaskmodal(true) }}>{task.description}</div>
                <div><b>Start Date:</b> {Helper.ConvertUtcToLocalTime(task.start).split(" ")[0]+' '+Helper.ConvertUtcToLocalTime(task.start).split(" ")[1].split(":")[0]+":"+Helper.ConvertUtcToLocalTime(task.start).split(" ")[1].split(":")[1]+Helper.ConvertUtcToLocalTime(task.start).split(" ")[2]}</div>
                <div><b>End Date:</b> {Helper.ConvertUtcToLocalTime(task.end).split(" ")[0]+' '+Helper.ConvertUtcToLocalTime(task.end).split(" ")[1].split(":")[0]+":"+Helper.ConvertUtcToLocalTime(task.end).split(" ")[1].split(":")[1]+Helper.ConvertUtcToLocalTime(task.end).split(" ")[2]}</div>
                <div><b>Priority:</b> <Badge bg={task.priority == 'low' ? 'dark' : task.priority == 'high' ? 'danger' : 'primary'}>{task.priority.toUpperCase()}</Badge></div>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><b>Project Category:</b> {(task.categoryname == undefined || task.categoryname == null || task.categoryname == '') ? 'None' : task.categoryname}</div>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><b>Project Assigned:</b> {(task.project == undefined || task.project == null || task.project == '') ? 'None' : task.project}</div>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><b>User:</b> {(task.username == undefined || task.username == null || task.username == '') ? 'None' : task.username}</div>
                {/* <div>
                  <button></button>
                  <button></button>
                </div> */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      )):(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:100}}><div>No Data</div></div>)}
      {(filteredData && filteredData.length > 0) && <Pagination>
        <Pagination.First onClick={() => handleFirstLastPage(0)} disabled={page === 0} />
            <Pagination.Prev onClick={() => handlePageChange('prev')} disabled={page === 0} />
            {/* {Array.from({ length: totalPages }, (v, i) => i + 1).map(page=>(
              <Pagination.Item key={page} active={(page - 1) === data.currentpage} onClick={() => setPage(page-1)}>{page}</Pagination.Item>
            ))} */}
            {renderPageNumbers()}
            <Pagination.Next onClick={() => handlePageChange('next')} disabled={page === (totalPages-1)} />
            <Pagination.Last onClick={() => handleFirstLastPage(totalPages-1)} disabled={page === totalPages-1} />
          </Pagination>}
    </Row> : <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><LoadingComponent /></div>) : <Row style={{margin:10}}>
    <Col md={12} ref={printRef} >
    <DataTable
            columns={columns}
            data={filteredData}
            // selectableRows
            // onSelectedRowsChange={selectedRowChange}
            // clearSelectedRows={toggledClearRows}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            conditionalRowStyles={conditionalRowStyles}
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
       
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChangeTable}
            highlightOnHover
            actions={actionMemo}
            style={{ marginTop: 10, fontSize: 10 }}
            dense
          />

    {/* <Table hover width={"100%"} style={{border: "1px solid black"}}>
      <tdead style={{backgroundColor:"#D9E1FF", border: "1px solid black"}}>
        <tr>
          <td width={"15%"} style={{borderRight:"1px solid black"}}>Task</td>
          <td width={"25%"} style={{borderRight:"1px solid black"}}>Description</td>
          <td width={"5%"} style={{borderRight:"1px solid black"}}>Start</td>
          <td width={"5%"} style={{borderRight:"1px solid black"}}>End</td>
          <td width={"5%"}style={{borderRight:"1px solid black"}}>Priority</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>Project Category</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>Project Assigned</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>Assigned By</td>
          <td width={"14%"}></td>
        </tr>
      </thead>
      <tbody>
      {filteredData.map((task) => (
        <tr>
          <td width={"15%"} style={{borderRight:"1px solid black"}} >{task.task}</td>
          <td width={"25%"} style={{borderRight:"1px solid black"}}>{task.description}</td>
          <td width={"5%"} style={{borderRight:"1px solid black"}}>{Helper.ConvertUtcToLocalTime(task.start).split(" ")[0]}</td>
          <td width={"5%"} style={{borderRight:"1px solid black"}}>{Helper.ConvertUtcToLocalTime(task.end).split(" ")[0]}</td>
          <td width={"5%"}style={{borderRight:"1px solid black"}}>{task.priority}</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>{(task.categoryname == undefined || task.categoryname == null || task.categoryname == '') ? 'None' : task.categoryname}</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>{(task.project == undefined || task.project == null || task.project == '') ? 'None' : task.project}</td>
          <td width={"10%"}style={{borderRight:"1px solid black"}}>{(task.createdbyusername == undefined || task.createdbyusername == null || task.createdbyusername == '') ? 'None' : task.createdbyusername}</td>
          <td width={"14%"} style={{borderRight:"1px solid black"}}><Button style={{margin:10}} onClick={() => { modalpopup(task); setshowedit(true) }} variant="primary" size='sm'><FaEdit /></Button><Button style={{margin:1}} size='sm' onClick={() => handletaskdelete(task.id)} variant="danger"><MdDelete />
</Button> {task.status === 'new' ? <>
                        <Button variant='warning' style={{margin:10}} size='sm' onClick={() => handlestatuschange(task.id, 'inprogress')}><GrInProgress />
</Button>
                        <Button style={{margin:10,backgroundColor:'green'}} size='sm' onClick={() => handlestatuschange(task.id, 'completed')}><FaCheck />
</Button>
                      </> :  <Button style={{margin:10,backgroundColor:'green'}} size='sm' onClick={() => handlestatuschange(task.id, 'completed')}><FaCheck />
</Button>}</td>
        </tr>
      ))}
        
      </tbody>
    </Table> */}
    </Col>
      </Row>}

      <Modal show={showaddtask} onHide={() => setshowaddtask(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={task.task}
                onChange={(e) => { setTask({ ...task, task: e.target.value }) }}
              />
              <Form.Label >Assign To <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    isClearable={true}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  isClearable={true}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 100,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={task.description}
                onChange={(e) => { setTask({ ...task, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={localStartDate}
                onChange={(e) => { setTask({ ...task, start: new Date(e.target.value).toISOString() });setLocalStartDate(dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }}
              />

              <Form.Label>End Date <span style={{color:'red'}}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={localEndDate}
                onChange={(e) => { setTask({ ...task, end: new Date(e.target.value).toISOString() });setLocalEndDate(dateToIsoStringWithoutChange(new Date(e.target.value)).substr(0,16)) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority <span style={{color:'red'}}>*</span>: </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'low'}/>
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'medium'}/>
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'high'}/>
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowaddtask(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlesubmit}
          >
            Create Task
          </Button>
        </Modal.Footer>


      </Modal>

      <Modal className='task-detail' show={showtaskmodal} onHide={() => setshowtaskmodal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ maxHeight: '100%', overflowWrap: 'anywhere' }}>{currentmodaldata.task}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table style={{ borderColor: "#000" }} className='table'>
            <tr>
              <td className='tableheadme'>Task</td>
              <td> : </td>
              <td style={{ maxHeight: '100%', overflowWrap: 'anywhere' }}>{currentmodaldata.task}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Description</td>
              <td> : </td>
              <td style={{ maxHeight: '100%', overflowWrap: 'anywhere' }}>{currentmodaldata.description}</td>
            </tr>
            <tr>
              <td className='tableheadme'>User</td>
              <td> : </td>
              <td style={{ maxHeight: '100%', overflowWrap: 'anywhere' }}>{currentmodaldata.username}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Start</td>
              <td> : </td>
              <td>{Helper.ConvertUtcToLocalTime(currentmodaldata.start)}</td>
            </tr>
            <tr>
              <td className='tableheadme'>End</td>
              <td> : </td>
              <td>{Helper.ConvertUtcToLocalTime(currentmodaldata.end)}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Priority</td>
              <td> : </td>
              <td>{currentmodaldata.priority}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Assigned Project</td>
              <td> : </td>
              <td>{(currentmodaldata.project == undefined || currentmodaldata.project == null || currentmodaldata.project == '') ? 'None' : currentmodaldata.project}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Task Category</td>
              <td> : </td>
              <td>{(currentmodaldata.categoryname == undefined || currentmodaldata.categoryname == null || currentmodaldata.categoryname == '') ? 'None' : currentmodaldata.categoryname}</td>
            </tr>
            <tr>
              <td className='tableheadme'>Assigned By</td>
              <td> : </td>
              <td>{(currentmodaldata.createdbyusername == undefined || currentmodaldata.createdbyusername == null || currentmodaldata.createdbyusername == '') ? 'None' : currentmodaldata.createdbyusername}</td>
            </tr>

          </table>

        </Modal.Body>
      </Modal>

      <Modal show={showedit} onHide={() => setshowedit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title</Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={currentmodaldata.task}
                onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, task: e.target.value }) }}
              />
              <Form.Label>Assign To</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select
                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                  isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                style={{
                  height: 100,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={currentmodaldata.description}
                onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, description: e.target.value }) }} />

              <Form.Label>Start Date</Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcStartDate.split(':')[0] + ":" + utcStartDate.split(':')[1]}
                onChange={(e) => { setUtcStartDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setCurrentModaldata({ ...currentmodaldata, start: new Date(e.target.value).toISOString() }) }}
              />

              <Form.Label>End Date</Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcEndDate.split(':')[0] + ":" + utcEndDate.split(':')[1]}
                onChange={(e) => { setUtcEndDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setCurrentModaldata({ ...currentmodaldata, end: new Date(e.target.value).toISOString() }) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority : </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value='low' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'low'} />
                    <span style={{ margin: '5px' }}>Low</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value='medium' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'medium'} />
                    <span style={{ margin: '5px' }}>Medium</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value='high' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'high'} />
                    <span style={{ margin: '5px' }}>High</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowedit(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleeditsubmit}
          >
            Update Task
          </Button>
        </Modal.Footer>


      </Modal>
    </>

  )
}

export default Taskpage;